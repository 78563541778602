import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import Color from 'color';

import moment from 'moment';



export default class PriceChart extends Component {

  constructor(props) {
    super(props);

    this.chartColors = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      // grey: 'rgb(201, 203, 207)'
    };

    this.state = {

      rendered: false,

      chartReference: undefined,

      chart: {
        labels: [],
        datasets: [
          {
            label: 'Prezzo massimo',
            fill: false,
            borderColor: this.chartColors.blue,
            backgroundColor: Color(this.chartColors.blue).lighten(0.2).rgb(),
          },
          {
            label: 'Prezzo minimo',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.red,
            backgroundColor: Color(this.chartColors.red).lighten(0.2).rgb(),
          },
          {
            label: 'Prezzo medio',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.green,
            backgroundColor: Color(this.chartColors.green).lighten(0.2).rgb(),
          },
          {
            label: 'Il tuo prezzo',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.purple,
            backgroundColor: Color(this.chartColors.purple).lighten(0.2).rgb(),
          },
          {
            label: 'Numero di offerte',
            yAxisID: 'Offerts',
            fill: false,
            hidden: false,
            borderColor: this.chartColors.yellow,
            backgroundColor: Color(this.chartColors.yellow).lighten(0.2).rgb(),
          }
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: true,
          text: 'Analisi prodotto'
        },
        legend: {
          position: 'bottom',
          // onClick: this.chartLabelAction,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Data'
            }
          }],
          yAxes: [
            {
              id: 'Price',
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Prezzo'
              },
              ticks: {
                precision: 2,
                beginAtZero: true,
              },
            },
            {
              id: 'Offerts',
              display: true,
              type: 'linear',
              position: 'right',
              scaleLabel: {
                display: true,
                labelString: 'Numero offerte'
              },
              ticks: {
                callback: function (value, index, values) {
                  if (Math.floor(value) === value) {
                    return value;
                  }
                }
              },
            },
          ]
        }
      },

    };

  }

  async componentDidUpdate() {
    if (!!!Object.keys(this.props.productHistory).length || this.state.rendered) {
      return;
    }

    if (typeof this.props.productHistory.data != 'undefined') {

      this.setState({ rendered: true });


      let chart = this.state.chart;
      chart.labels = this.props.productHistory.data.map(x => moment(x.pricedate).format('DD/MM/YYYY'));
      chart.datasets[0].data = this.props.productHistory.data.map(x => Number(x.maxprice.toFixed(2)));
      chart.datasets[1].data = this.props.productHistory.data.map(x => Number(x.minprice.toFixed(2)));
      chart.datasets[2].data = this.props.productHistory.data.map(x => Number(x.avgprice.toFixed(2)));
      chart.datasets[3].data = this.props.productHistory.data.map(
        function (x) {
          if (!!x.shopprice) {
            return Number(x.shopprice.toFixed(2))
          } else {
            return 0;
          }
        }
      );
      chart.datasets[4].data = this.props.productHistory.data.map(x => x.totcount);



      this.setState({ chart });
    } else {
      return;
    }

  }

  render() {
    return (
      <div>
        <Line
          ref={(reference) => this.chartReference = reference}
          data={this.state.chart}
          options={this.state.options}
          height={100}
        />
      </div>
    )
  }

}