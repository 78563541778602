import { authHeader } from '../_helpers/auth-header';
// import { decodeJWT } from '../_helpers/auth-jwt';

import Resource from '../_helpers/resource';
const resource = new Resource(process.env.REACT_APP_API_URL);

export const authService = {
  isAuthenticated,
  login,
  loginOneTimePassword,
  updateUserStrategy,
  resetPassword,
  logout,
  renew,
  retrivePublicCertificate,
};

function isAuthenticated() {
  return true;
}

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/auth/strategy/password`, requestOptions)
    .then(handleResponse)
    .then((auth) => {
      if (auth) {
        localStorage.setItem('auth', JSON.stringify(auth));
      }
      return auth;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function loginOneTimePassword(token) {
  return resource
    .post(`auth/strategy/oneTime`, { token })
    .then((auth) => {
      if (auth) localStorage.setItem('auth', JSON.stringify(auth));
      return auth;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function updateUserStrategy(userId, strategy, data) {
  return resource.put(`user/${userId}/auth/strategy/${strategy}`, { data }, authHeader());
}

function resetPassword(email) {
  return resource.post(`auth/strategy/password/reset`, { email });
}

function logout() {
  const auth = localStorage.getItem('auth');

  if (!auth) {
    return;
  }

  const requestOptions = {
    method: 'DELETE',
    headers: Object.assign({}, { 'Content-Type': 'application/json' }, authHeader()),
  };

  localStorage.removeItem('auth');
  localStorage.removeItem('user');
  localStorage.removeItem('certificate');
  localStorage.removeItem('cacheData');
  localStorage.removeItem('datapicker');

  return fetch(`${process.env.REACT_APP_API_URL}/auth/strategy/jwt`, requestOptions)
    .then(handleResponse)
    .then(
      () => {
        window.location.replace('/user/login');
      },
      (error) => {
        window.location.replace('/user/login');
        throw new Error(error);
      }
    );
}

function renew() {
  const requestOptions = {
    method: 'PUT',
    headers: Object.assign({}, { 'Content-Type': 'application/json' }, authHeader()),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/auth/strategy/jwt`, requestOptions)
    .then(handleResponse)
    .then((auth) => {
      localStorage.setItem('auth', JSON.stringify(auth));
      return auth;
    })
    .catch((error) => {
      try {
        window.location.replace('/user/login');
      } catch (error) {
        console.error(error);
        throw new Error(error);
      }
    });
}

function retrivePublicCertificate() {
  const requestOptions = {
    method: 'GET',
    headers: Object.assign({}, { 'Content-Type': 'application/json' }, authHeader()),
  };

  let certificate = localStorage.getItem('certificate');
  if (certificate) {
    certificate = JSON.parse(certificate);
    return Promise.resolve(certificate);
  }

  return fetch(`${process.env.REACT_APP_API_URL}/auth/certificate/public`, requestOptions)
    .then(handleResponse)
    .then(
      (response) => {
        localStorage.setItem('certificate', JSON.stringify(response));
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
}

function handleResponse(response) {
  switch (response.status) {
    case 204:
      return Promise.resolve(true);

    case 401:
      return response.text().then((text) => {
        try {
          window.location.replace('/user/login');
        } catch (error) {
          console.error(error);
        }
        return Promise.reject(text);
      });

    case 200:
    default:
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }

      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return data;
      });
  }
}
