import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { analyticsService } from '../../../_services/analytics.service';
import IncomeChartsPerc from './Charts/IncomeChartsPerc';
import IncomeChartsEuro from './Charts/IncomeChartsEuro';
import moment from 'moment';

export default class ECommerceIncome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incomesStatsPerc: null,
      incomesStatsEuro: null,
      incomesStatsDayPerc: null,
      incomesStatsDayVsDayPerc: null,
    };
  }

  async componentDidMount() {
    const { user, shop, from, to } = this.props;

    if (!!user.id && !!shop.id) {
      try {
        const incomesStatsPerc = await analyticsService.getIncomesStatsPerc(
          user.id,
          shop.id,
          from.toISOString(),
          to.toISOString()
        );

        const incomesStatsEuro = await analyticsService.getIncomesStatsEuro(
          user.id,
          shop.id,
          from.toISOString(),
          to.toISOString()
        );

        const incomesStatsDayPerc = await analyticsService.getIncomesStatsDayPerc(
          user.id,
          shop.id,
          moment().startOf('day').toISOString(),
          moment().startOf('day').toISOString()
        );

        const incomesStatsDayVsDayPerc = await analyticsService.getIncomesStatsDayVsDayPerc(
          user.id,
          shop.id,
          moment().startOf('day').toISOString(),
          moment().subtract(7, 'days').startOf('day').toISOString()
        );

        let cumulativeTotal = 0;
        let cumulativeShop = 0;
        let incrementalIncomesStatsDayVsDayPerc = incomesStatsDayVsDayPerc
          .sort((a, b) => a.data - b.data) // 🔁 Ordina dalla data più vecchia alla più recente
          .map((item) => {
            cumulativeTotal += item.total;
            cumulativeShop += item.shop;

            return {
              data: item.data,
              total: cumulativeTotal,
              shop: cumulativeShop
            };
          });



        await this.setState({ incomesStatsPerc, incomesStatsEuro, incomesStatsDayPerc, incomesStatsDayVsDayPerc, incrementalIncomesStatsDayVsDayPerc });
      } catch (error) {
        console.error(error);
      }
    }

    window.removeLoader();
  }

  render() {
    const { user, shop } = this.props;
    const { incomesStatsPerc, incomesStatsEuro, incomesStatsDayPerc, incomesStatsDayVsDayPerc, incrementalIncomesStatsDayVsDayPerc } = this.state;

    return !user.id || !shop.id ? (
      <Alert color="warning">
        Non hai ancora un negozio associato. <Link to="/shop">Vai al form di creazione.</Link>
      </Alert>
    ) : (
      <div>
        <div className="row">
          <div className="col-12 mT-20">
            <IncomeChartsPerc statsHistory={incomesStatsPerc} />
          </div>

          <div className="col-12 mT-20">
            <IncomeChartsPerc statsHistory={incomesStatsDayPerc} />
          </div>

          <div className="col-6 mT-20">
            <IncomeChartsPerc statsHistory={incomesStatsDayVsDayPerc} />
          </div>

          <div className="col-6 mT-20">
            <IncomeChartsPerc statsHistory={incrementalIncomesStatsDayVsDayPerc} />
          </div>

          <div className="col-12 mT-20">
            <IncomeChartsEuro statsHistory={incomesStatsEuro} />
          </div>
        </div>
      </div>
    );
  }
}
